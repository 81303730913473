/* eslint-disable @typescript-eslint/naming-convention */
import { LteStatus } from './DeviceStatus';

export interface Device extends Record<string, unknown> {
  isActive?: boolean;
  category?: string;
  createdAt?: string;
  name: string;
  serialNumber: string;
  type: string;
  updatedAt?: string;
  uuid: string;
  groupUuid?: string;
  groupName?: string;
  status: string;
  connectionStatus: string;
  location?: string;
  details: Details;
  locationLongitude?: string;
  locationLatitude?: string;
  custom: Record<string, string>;
  firmwareAutoUpdate: boolean;
  autoUpdateCertificateUuid?: string;
  deviceControlCertificateUuid?: string;
  eventsTopic?: string;
  connectionPongTimeout?: number;
  connectionPingInterval?: number;
  connectionSessionTimeout?: number;
  realmURI?: string;
  url: string;
  connectionLastMessageAt?: string;
  smartUpdateHistory: DeviceUpdateHistory[];

  smartUpdateInfo: SmartUpdateInfo;
  ip?: string;
  hardwareModel: string;
  firmwareName: string;
  firmwareVersion: string;
}
// TODO: recheck the status field with BE data
interface Details {
  dirty: boolean;
  lastCheck: string;
  error?: ErrorInfo;
  updatedAt: string;
  status?: {
    lte2?: LteStatus;
    lte2_2?: LteStatus;
    lte_serial2?: LteStatus;
    lte3?: LteStatus;
    lte3_2?: LteStatus;
    lte4?: LteStatus;
    lte4_2?: LteStatus;
    lte5?: LteStatus;
    lte5_2?: LteStatus;
  };
}

interface ErrorInfo {
  code: string;
  time: string;
}

interface DeviceUpdateHistory extends Record<string, unknown> {
  progress: string;
  executedAt: string;
  targetVersion?: string;
  prevVersion?: string;
  updateImage?: string;
  timezone?: string;
}

export interface ConnectionInfo {
  connectionStatus: string;
  url: string;
  deviceId: string;
}

export interface FirmwareVersion {
  version: string;
  deviceId: string;
}

export interface DeviceGroup extends Record<string, unknown> {
  category?: string;
  type?: string;
  uuid: string;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  managedDevicesCount: number;
}

interface SmartUpdateInfo {
  status: SmartUpdateStatus;
  targetVersion: string;
  errorCode?: string;
  plannedTime: string;
  timezone: string;
}

export enum SmartUpdateStatus {
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
  LATEST = 'LATEST',
  FAILED = 'FAILED',
  UNKNOWN = 'UNKNOWN'
}
