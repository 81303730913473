export const DEPLOYMENT_TARGET_TYPE_MANAGED_DEVICES = 'MANAGED_DEVICES';
export const DEPLOYMENT_TARGET_TYPE_DEVICE_GROUPS = 'DEVICE_GROUPS';
export const DEPLOYMENT_TARGET_TYPE_ALL = 'ALL';

export const DEPLOYMENT_METHOD_AUTO_UPDATE = 'AUTO_UPDATE';
export const DEPLOYMENT_METHOD_UPDATE_JOB = 'UPDATE_JOB';

export const DEPLOYMENT_SUBJECT_UPDATE_PACKET = 'UPDATE_PACKET';
export const DEPLOYMENT_SUBJECT_CLI_TEMPLATE = 'CLI_TEMPLATE';

export const DEPLOYMENT_UPDATE_JOB_STATUS_READY = 'READY';
export const DEPLOYMENT_UPDATE_JOB_STATUS_SCHEDULED = 'SCHEDULED';
export const DEPLOYMENT_UPDATE_JOB_STATUS_RUNNING = 'RUNNING';
export const DEPLOYMENT_UPDATE_JOB_STATUS_SUSPENDED = 'SUSPENDED';
export const DEPLOYMENT_UPDATE_JOB_STATUS_COMPLETED = 'COMPLETED';
export const DEPLOYMENT_UPDATE_JOB_STATUS_CANCELED = 'CANCELED';
export const DEPLOYMENT_UPDATE_JOB_STATUS_FAILED = 'FAILED';

export const UPDATE_JOB_PROGRESS_READY = 'READY';
export const UPDATE_JOB_PROGRESS_RUNNING = 'RUNNING';
export const UPDATE_JOB_PROGRESS_SUCCEEDED = 'SUCCEEDED';
export const UPDATE_JOB_PROGRESS_PARTIALLY_SUCCEEDED =
  'PARTIALLY_SUCCEEDED';
export const UPDATE_JOB_PROGRESS_FAILED = 'FAILED';

export const UPDATE_JOB_DEVICE_PROGRESS_RETRYING = 'RETRYING';
export const UPDATE_JOB_DEVICE_PROGRESS_SUBMITTING = 'SUBMITTING';
export const UPDATE_JOB_DEVICE_PROGRESS_SOFTWARE_UPDATE = 'SOFTWAREUPDATE';
export const UPDATE_JOB_DEVICE_PROGRESS_RUNNING = 'RUNNING';
export const UPDATE_JOB_DEVICE_PROGRESS_SUCCEEDED = 'SUCCEEDED';
export const UPDATE_JOB_DEVICE_PROGRESS_FAILED = 'FAILED';
export const UPDATE_JOB_DEVICE_PROGRESS_TIMEOUT = 'TIMEOUT';
export const UPDATE_JOB_DEVICE_PROGRESS_UNKNOWN = 'UNKNOWN';

export enum UpdateJobResult {
  UPDATED_SUCCESS = '0',
  UPDATED_IGNORED = '1',
  UPDATED_LIST_PRECOND_UNMET = '2',
  UPDATED_ERROR = '3',
  UPDATED_ERROR_UNIMPLEMENTED = '4',
  UPDATED_ERROR_BUSY = '5',
  UPDATED_ERROR_DISK_SPACE = '6',
  UPDATED_ERROR_FILE_CORRUPT = '7',
  UPDATED_ERROR_BASE_VERSION_MISSING = '8',
  UPDATED_ERROR_DECRYPTION = '9',
  UPDATED_ERROR_MANIFEST_MISMATCH = '10',
  UPDATED_ERROR_KERNEL_FAILED = '11',
  UPDATED_ERROR_FILESYSTEM = '12',
  UPDATED_ERROR_DOWNLOAD_PACKAGE = '13',
  UPDATED_ERROR_DOWNLOAD_LIST = '14',
  UPDATED_ERROR_SERVER_UNREACHABLE = '15',
}
